import { useCallback, useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Table,
    Form,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

import {
    selectedVariations,
    buildShopSupplyAddToCart,
    editBuildShopSupplyAddToCart
} from 'actions/admin/order';
import SetProductQuantity from './SetProductQuantity';
import ButtonSpinner from 'views/ButtonSpinner';

const BuildShopSupplyModel = ({
    selectedVariations,
    variationData,
    buildShopSupplyAddToCart,
    editBuildShopSupplyAddToCart,
    buildShopSupplyCart
}) => {
    const [isProductLoading, setIsProductLoading] = useState(false);
    useEffect(() => {
        if (variationData.openBuildShopModel === true) {
            const stockq = variationData.buildShopSupplyDetail.find(o => o.build_shop_supply_id === variationData.buildShopSupplyProduct.build_shop_supply_id);

            variationData.stock_quantity = variationData.buildShopSupplyProduct.isEdit === true ? stockq?.stock_quantity : variationData.buildShopSupplyProduct?.stock_quantity
            selectedVariations(variationData)
        }
    }, [variationData.openBuildShopModel])
    const toggle = () => {
        variationData = {
            ...variationData,
            openBuildShopModel: !variationData.openBuildShopModel
        }

        if (variationData?.openBuildShopModel === false) {
            variationData = {
                ...variationData,
                buildShopSupplyProduct: {},
                quantity: 1,
            }
            delete variationData.stock_quantity;

        }
        selectedVariations(variationData)
    };

    const handleOnChange = useCallback((event) => {
        const { value } = event.target;
        variationData = {
            ...variationData,
            buildShopSupplyProduct: {
                ...variationData.buildShopSupplyProduct,
                price: value
            }
        }
        selectedVariations(variationData)
    });

    const submitForm = (event) => {
        event.preventDefault();
        let selectedPro = variationData.buildShopSupplyProduct
        selectedPro.quantity = variationData.quantity
        selectedPro.type = "buildShopSupply";
        if (selectedPro.isEdit === true) {
            selectedPro.quantity = variationData.buildShopSupplyProduct?.quantity
        }


        setIsProductLoading(true)
        selectedPro.orderId = variationData.orderId !== undefined ? variationData.orderId : variationData.userId
        let addEditCard = selectedPro.isEdit === true ? editBuildShopSupplyAddToCart(selectedPro) : buildShopSupplyAddToCart(selectedPro)
        addEditCard.then((res) => {
            if (res.status === true) {
                variationData = {
                    ...variationData,
                    // buildShopSupplyProduct: {},
                    quantity: 1,
                    callGetCartApi: true,
                    openBuildShopModel: false
                }

            } else {
                variationData = {
                    ...variationData,
                    openBuildShopModel: true
                }
            }
            setIsProductLoading(false)
            selectedVariations(variationData)
        })
    }
    return (
        <>
            <Modal isOpen={variationData.openBuildShopModel} toggle={toggle}>
                <ModalHeader toggle={toggle}>Build Shop Supply Product ({variationData?.buildShopSupplyProduct?.product_title})</ModalHeader>
                <Form onSubmit={submitForm} className="form-horizontal">
                    <ModalBody>
                        <Row>
                            <Col sm="12">
                                <SetProductQuantity
                                    customShaftChecked={true}
                                />
                            </Col>
                            <Col sm="12" className={"mt-2"}>
                                <FormGroup>
                                    <Label for={"price"} ><b>Price</b></Label>
                                    <Input id="price" name="price" value={variationData?.buildShopSupplyProduct?.price} type="number" min={0.01} step="0.01" onChange={handleOnChange} required />
                                </FormGroup>
                            </Col>
                            <Col sm="12" className={"mt-2"}>
                                {/* <FormGroup>
                                    <Label ><b>Additional Product</b> : <span>{variationData?.buildShopSupplyProduct?.name}</span></Label>
                                </FormGroup> */}
                                <FormGroup>
                                    <Label ><b> SKU</b> : <span>{variationData?.buildShopSupplyProduct?.sku}</span></Label>
                                </FormGroup>
                                {variationData?.buildShopSupplyProduct?.description && <FormGroup>
                                    <Label ><b>Description</b> : <span dangerouslySetInnerHTML={{
                                        __html: (variationData?.buildShopSupplyProduct?.description),
                                    }} /></Label>
                                </FormGroup>}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div className="modalFooter">
                            {isProductLoading ? <ButtonSpinner /> : <Button type="submit" color="primary">
                                Submit
                            </Button>}{' '}
                            <Button disabled={isProductLoading} color="danger" onClick={toggle}>
                                Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
    variationData: state.order.variationData,
    buildShopSupplyCart: state.order.buildShopSupplyCart
});

export default connect(mapStateToProps, {
    selectedVariations,
    buildShopSupplyAddToCart,
    editBuildShopSupplyAddToCart
})(BuildShopSupplyModel)